import React from 'react'

import { Head } from '../../components/Head'
import { ListingExample } from '../../examples/react/pages/ListingExample'

interface PageProps {
  location: Location
}

const Page = ({ location }: PageProps) => (
  <>
    <Head title="Työpaikkailmoitukset" />
    <ListingExample location={location} />
  </>
)

export default Page
